import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Aside from '../components/aside'
import ThemesList from '../components/themes-list'
import themes from '../data/themesInfo'
import { Helmet } from "react-helmet"
// import PubSub from "pubsub-js";

class Index extends React.Component {

  showDemo=(e)=>{
    console.log('e:',e)
    // PubSub.publish('showdemo',e)
  }
  render() {

    const allThemes=themes.allThemes
    const theme=allThemes[0]
    return (
      <Layout >
        <div className="pagewidth clearfix">
          <div id="content">
            <h1 className="page-title">全部模板</h1>
            <div className="featured-theme-section clearfix">
              <div className="col2-1 first">
                <figure className="featured-theme-image">
                  <Link to={theme.path}>
                    <img src={theme.responsiveImg} alt="featured" />
                  </Link>
                </figure>
              </div>
              <div className="col2-1">
                <div className="featured-theme-content">
                  <h3 className="featured-theme-title">{theme.name}</h3>
                  <p className="featured-theme-description">{theme.desc}</p>
                  <p><Link to={theme.path}  className="button red small">More Info</Link>
                    {/*<a  href="#" onClick={()=>this.showDemo(theme.src)} className="button green small lightbox">Demo</a>*/}
                  </p>
                </div>
              </div>
            </div>
            <ThemesList themes={allThemes}/>
          </div>
          <Aside />
        </div>


      </Layout>
    )
  }
}

export default Index



